// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11219(466150b847)-C23/01/2025-22:51:42-B23/01/2025-23:27:21' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11219(466150b847)-C23/01/2025-22:51:42-B23/01/2025-23:27:21",
  branch: "master",
  latestTag: "6.1",
  revCount: "11219",
  shortHash: "466150b847",
  longHash: "466150b8474d1d92e36e5f025502deddebf2d0b5",
  dateCommit: "23/01/2025-22:51:42",
  dateBuild: "23/01/2025-23:27:21",
  buildType: "Ansible",
  } ;
