import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from "../../../../core/utils/utils.service";
import {PlcRepas, PlcRepasWeek} from "../../menu-plc-resolver.service";
import {ContratMenuConviveRepasPlcDateDTO} from "../../../../core/dtos/cmcr-plc-date-dto";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {EffectifMode} from '../../constant/menu-plc.constant';
import {MSG_KEY, MSG_SEVERITY, STATUTS_MENUS} from 'app/core/constants';
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {CmcrPlcDateService} from "../../../../core/services/entities/cmcr-plc-date.service";
import {ContratMenuConvive__ContrainteAlimDTO} from "../../../../core/dtos/contratmenuconvive__contrainte-alim-dto";
import {find as _find} from "lodash";
import {debounceTime, switchMap} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-repas-plc',
  templateUrl: './repas-plc.component.html',
  styleUrls: ['./repas-plc.component.scss']
})
export class RepasPlcComponent implements OnInit, OnDestroy {

  subTypeEffectif: Subscription;
  subChangeEffectifRepas: Subscription;
  subRefreshRepas: Subscription;
  subSelectedCell: Subscription;

  colWidth: 80;

  STATUTS_MENUS = STATUTS_MENUS;
  EffectifMode = EffectifMode;
  typeEffectif = EffectifMode.Prevision;

  selectedCell: ContratMenuConviveRepasPlcDateDTO;

  @Input('plcRepasWeek') set prw(value: PlcRepasWeek) {

    this._prw = value;
  };

  _prw: PlcRepasWeek;

  constructor(public utils: UtilsService,
              private cmcrPlcDateSvc: CmcrPlcDateService,
              private auth2Svc: Auth2Service,
              private plcSvc: PointDeLivraisonService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.typeEffectifSubscription();
    this.changePlcEffectifRepasSubscription();
    // savoir quelle cellule est sélectionnée lorsqu'on a une grille multiple déjeuner/gouter/dîner
    this.changeSelectedCellSubscription();
    this.refreshRepasSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTypeEffectif);
    this.utils.unsubscribe(this.subChangeEffectifRepas);
    this.utils.unsubscribe(this.subRefreshRepas);
    this.utils.unsubscribe(this.subSelectedCell);
  }

  typeEffectifSubscription = () => {
    this.subTypeEffectif = this.plcSvc.typeEffectif$.subscribe(response => this.typeEffectif = response);
  };

  changePlcEffectifRepasSubscription = () => {
    this.subChangeEffectifRepas = this.cmcrPlcDateSvc.changePlcEffectifRepas$.pipe(
      debounceTime(500),
      switchMap(cell => this.cmcrPlcDateSvc.saveEffectifRepasPlc(cell, this.typeEffectif, false))
    ).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {
          this.showRepas(this.selectedCell);
        }
      }
    );
  };

  refreshRepasSubscription = () => {
    this.subRefreshRepas = this.plcSvc.refreshRepas$.subscribe(() => this.showRepas(this.selectedCell));
  }

  showRepas = (cell: ContratMenuConviveRepasPlcDateDTO) => {
    this.selectedCell = cell;
    this.plcSvc.announceSelectedCell(this.selectedCell);

    if (this.selectedCell) {
      this.plcSvc.loadPlcRepas(this.selectedCell).subscribe(response => {
        const plcRepas = new PlcRepas();
        plcRepas.rows = response.one.rows;
        plcRepas.canModifyEffectif = this.selectedCell.ligneFacturationId === 0;
        this.plcSvc.announcePlcRepas(plcRepas);
      });
    }
  };

  isEffectifInputDisabled = (cell: ContratMenuConviveRepasPlcDateDTO): boolean => {

    if (cell.statutMenuCode != STATUTS_MENUS.SAISIE_EFFECTIFS) {
      return true;
    } else {
      if (this.auth2Svc.isSiteLocal(cell.cmSiteId)) {
        return false;
      } else {
        return true;
      }
    }
  };

  changeEffectif = ($event: FocusEvent, cell: ContratMenuConviveRepasPlcDateDTO, propertyName: string) => {

    cell[propertyName] = +$event;
    this.selectedCell = cell;
    // declencher le traitement de sauvegarde d'un effectif repas plc (recalculer aussi les effectifs de ligne de plat)
    //this.cmcrPlcDateSvc.announceChangeEffectifRepas(cell);

    // Pour le moment, j'enlève le debounceTime qui me pose énormément de problèmes
    this.cmcrPlcDateSvc.saveEffectifRepasPlc(cell, this.typeEffectif, false)
                    .subscribe(response => {

                          if (!this.utils.isResponseSupplierError(response)) {
                            this.showRepas(this.selectedCell);
                          }
                        }
                      );
  };

  getCell = (prw: PlcRepasWeek, rowData: ContratMenuConvive__ContrainteAlimDTO, col: Date) => {
    if (!this.utils.isNullOrEmpty(prw) && !this.utils.isCollectionNullOrEmpty(prw.cells)) {
      return _find(prw.cells, {
        'cmcCaId': rowData.id,
        'dateRepas': col,
      });
    }
    return null;
  };

  save = () => {
    this.cmcrPlcDateSvc.saveEffectifsPlc(this.prw.cells)
      .subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Effectifs enregistrés avec succès`);
      });
  };

  private changeSelectedCellSubscription() {
    this.subSelectedCell = this.plcSvc.selectedCell$.subscribe((response: ContratMenuConviveRepasPlcDateDTO) => this.selectedCell = response);
  }
}
